import React from 'react'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'
import { getUser, logout } from './services/auth'
import { AccountContainer } from './components/AccountContainer'
import accountIcon from './images/account-icon.svg'
import { navigate } from 'gatsby'
import { Wrapper } from '../components/Wrapper'

const logoutCallback = () => {
  ReactGA.event({
    category: 'Account',
    action: 'Logout',
    label: 'Logout from account page'
  })
  navigate(`/user/login`)
}

const Profile = () => {
  const user = getUser()
  return (
    <Wrapper>
      <Helmet title={`Account | P+N Showcase`}>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <AccountContainer>
        <img src={accountIcon} alt="" />
        <h1>Account Settings</h1>
        <button
          className="btn-linkedin"
          onClick={event => {
            event.preventDefault()
            logout(() => logoutCallback())
          }}
          >
          Disconnect LinkedIn
        </button>
      </AccountContainer>
    </Wrapper>
  )
}

export default Profile