import React from 'react'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import ReactGA from 'react-ga'
import Container from '../components/Container'
import { handleLogin } from './services/auth'
import Profile from './profile'
import PrivateRoute from './components/privateRoute'
import Login from './login'
import Bookmarks from './bookmarks'

const responseLinkedin = response => {
  ReactGA.event({
    category: 'Account',
    action: 'Login',
    label: 'Login from account page'
  })
  handleLogin(response, user => navigate(`/user`))
}

const App = () => {
  return (
    <Container>
      <Router>
        <PublicRoute path="/user">
          <PrivateRoute path="/" component={Profile} />
          <Bookmarks path="/bookmarks" />
          <Login path="/login" linkedInCallback={responseLinkedin} />
        </PublicRoute>
      </Router>
    </Container>
  )
}
function PublicRoute(props) {
  return <div>{props.children}</div>
}

export default App